<template>
  <b-sidebar
    id="edit-sidebar"
    :visible="isEditSidebarActive"
    bg-variant="white"
    sidebar-class="sidebar-lg"
    shadow
    backdrop
    no-header
    right
    @change="(val) => $emit('update:is-edit-sidebar-active', val)"
  >
    <template #default="{ hide }">

      <!-- Header -->
      <div class="d-flex justify-content-between align-items-center content-sidebar-header px-2 py-1">
        <h5 class="mb-0">
          Edit Collection
        </h5>

        <feather-icon
          class="ml-1 cursor-pointer"
          icon="XIcon"
          size="16"
          @click="hide"
        />
      </div>

      <!-- form -->
      <validation-observer
        ref="refFormObserver"
        #default="{ handleSubmit, invalid }"
      >
        <b-form
          class="p-2"
          @submit.prevent="handleSubmit(onSubmit)"
        >
          <!-- name -->
          <b-form-group
            label="Collection name"
            label-for="collection-name"
          >
            <validation-provider
              #default="validationContext"
              name="Name"
              vid="name"
              rules="required"
            >
              <b-form-input
                id="collection-name"
                v-model="name"
                :state="getValidationState(validationContext)"
                name="name"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <!-- Client -->
          <b-form-group
            v-if="userRole != 'client-admin'"
            label="Client"
            label-for="client"
          >
            <validation-provider
              #default="validationContext"
              name="Client"
              vid="client"
              rules="required"
            >
              <v-select
                v-model="client"
                :options="clientOptions"
                :reduce="val => val.value"
                :clearable="false"
                input-id="client"
              />
              <b-form-invalid-feedback>
                {{ validationContext.errors[0] }}
              </b-form-invalid-feedback>
            </validation-provider>
          </b-form-group>

          <!-- Form Actions -->
          <div class="d-flex mt-2">
            <b-button
              v-ripple.400="'rgba(255, 255, 255, 0.15)'"
              variant="primary"
              class="mr-2"
              type="submit"
              :disabled="invalid"
            >
              Save
            </b-button>
            <b-button
              v-ripple.400="'rgba(186, 191, 199, 0.15)'"
              type="button"
              variant="outline-secondary"
              @click="hide"
            >
              Cancel
            </b-button>
          </div>

        </b-form>
      </validation-observer>
    </template>
  </b-sidebar>
</template>

<script>
import {
  BButton, BForm, BFormGroup, BFormInput, BFormInvalidFeedback, BSidebar,
} from 'bootstrap-vue'
import { ValidationProvider, ValidationObserver } from 'vee-validate'
import { required } from '@validations'
import formValidation from '@core/comp-functions/forms/form-validation'
import Ripple from 'vue-ripple-directive'

import vSelect from 'vue-select'

import ToastificationContent from '@core/components/toastification/ToastificationContent.vue'

export default {
  components: {
    BSidebar,
    BForm,
    BFormGroup,
    BFormInput,
    BFormInvalidFeedback,
    BButton,
    vSelect,

    // Form Validation
    ValidationProvider,
    ValidationObserver,
  },
  directives: {
    Ripple,
  },
  props: {
    isEditSidebarActive: {
      type: Boolean,
      required: true,
    },
    editCollectionId: {
      type: Number,
      required: true,
    },
    editCollectionName: {
      type: String,
      required: true,
    },
    editCollectionClient: {
      type: null,
      required: true,
    },
    clientOptions: {
      type: Array,
      default: () => [],
    },
    userRole: {
      type: String,
      required: true,
    },
  },
  data() {
    return {
      name: '',
      client: null,
      required,

      refFormObserver: null,
      getValidationState: null,
    }
  },
  watch: {
    editCollectionName(val) {
      this.name = val
    },
    editCollectionClient(val) {
      this.client = val
    },
  },
  created() {
    const {
      refFormObserver,
      getValidationState,
    } = formValidation(() => {})
    this.refFormObserver = refFormObserver
    this.getValidationState = getValidationState
  },
  methods: {
    onSubmit() {
      const data = { name: this.name }
      if (this.userRole !== 'client-admin') {
        data.client = this.client
      }
      this.$http.patch(`/api/v1/collections/${this.editCollectionId}/`, data)
        .then(() => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: 'Collection saved',
              icon: 'InfoIcon',
              variant: 'success',
            },
          })
          this.$emit('update:is-edit-sidebar-active', false)
          this.$emit('refetch-data')
          this.name = ''
          this.client = null
        })
        .catch(({ response }) => {
          this.$toast({
            component: ToastificationContent,
            position: 'top-right',
            props: {
              title: response.data.name ? response.data.name[0] : 'Save failed',
              icon: 'InfoIcon',
              variant: 'danger',
            },
          })
        })
    },
  },
}
</script>

<style lang="scss">
@import '@core/scss/vue/libs/vue-select.scss';

#add-new-sidebar {
  .vs__dropdown-menu {
    max-height: 200px !important;
  }
}
</style>
